import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SeasonFilter } from '@features/farmer/listFarmers/Filters/SeasonFilter/SeasonFilter';
import { Typography } from '@mui/material';
import { useNewFarmersTableHeaderStyles } from '@features/farmer/listFarmers/Header/NewFarmersTableHeader.style';
import { SearchInput } from '@components/Form/SearchInput/SearchInput';
import { useAppSelector } from '@hooks/redux';
import { selectFarmersListSearch } from '../newFarmersList.slice';
import { useTranslation } from 'react-i18next';
import { useGetFarmersList } from '../useGetFarmersList';
import { CohortFilter } from '@features/farmer/listFarmers/Filters/CohortFilter/CohortFilter';
import { useFarmersListFilters } from '@features/farmer/listFarmers/useFarmersListFilters';
import { ContactFilter } from '@features/farmer/listFarmers/Filters/ContactFilter/ContactFilter';
import { UserStatusFilter } from '../Filters/UserStatusFilter/UserStatusFilter';
export const NewFarmersTableHeader = () => {
    const { classes } = useNewFarmersTableHeaderStyles();
    const { t } = useTranslation();
    const { count } = useGetFarmersList();
    const search = useAppSelector(selectFarmersListSearch);
    const { defaultSeason, defaultCohorts, defaultContacts, defaultUserStatuses, handleSeasonChange, handleCohortChange, handleSearchChange, handleSearchReset, handleContactsChange, handleUserStatusChange, } = useFarmersListFilters();
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: classes.titleContainer }, { children: [_jsx(Typography, Object.assign({ variant: "h2" }, { children: t('common.my-farmers') })), !!defaultSeason && _jsx(SeasonFilter, { defaultValue: defaultSeason, onChange: handleSeasonChange })] })), _jsxs("div", Object.assign({ className: classes.filtersContainer }, { children: [_jsx(CohortFilter, { defaultValue: defaultCohorts, onChange: handleCohortChange }), _jsx(ContactFilter, { defaultValue: defaultContacts, onChange: handleContactsChange }), _jsx(UserStatusFilter, { defaultValue: defaultUserStatuses, onChange: handleUserStatusChange })] })), _jsxs("div", Object.assign({ className: classes.searchContainer }, { children: [_jsx(SearchInput, { defaultValue: search, onChange: handleSearchChange, onReset: handleSearchReset, placeholder: t('placeholders.search-name-email') }), _jsx(Typography, Object.assign({ variant: "body2" }, { children: t('common.farmer', { count }) }))] }))] }));
};
