import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewSelect } from '@components/NewSelect/NewSelect';
import { useUserStatuses } from './useUserStatuses';
export const UserStatusFilter = ({ defaultValue, onChange }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : []);
    const userStatuses = useUserStatuses();
    const handleChange = (values) => {
        setInputValue(values);
        onChange === null || onChange === void 0 ? void 0 : onChange(values);
    };
    const getOptionLabel = (userStatus) => userStatus;
    const getOptionValue = (userStatus) => userStatus;
    if (!userStatuses)
        return _jsx(_Fragment, {});
    return (_jsx(NewSelect, { values: inputValue, onChange: handleChange, options: userStatuses, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (userStatuses, allAreSelected) => (_jsx(NewSelect.LabelWithPill, { label: t('teams.grid.columns.user-status'), selected: userStatuses.length, areAllSelected: allAreSelected })), renderOption: (option, props) => (_jsx(NewSelect.CheckboxOption, Object.assign({}, props, { label: t(`statuses.user.${option}`) }))), renderOptionAll: (props) => _jsx(NewSelect.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};
