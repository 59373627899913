import { makeStyles } from 'tss-react/mui';
export const useNewFarmersTableHeaderStyles = makeStyles()((theme) => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    filtersContainer: {
        marginTop: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    searchContainer: {
        marginTop: theme.spacing(7),
        display: 'flex',
        '& > div': {
            width: 380,
        },
        alignItems: 'center',
        gap: theme.spacing(4),
    },
}));
