import { useAuth } from '@hooks';
import { usePermission } from '@hooks/usePermission';
import { RESULTS_CALCULATION_STATUS, useGetUserNameByIdQuery } from '@services';
import { CARBON_RESULT_MODEL } from '@utils/constants';
import { formatDate } from '@utils/date';
import { useTranslation } from 'react-i18next';
export const RESULT_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    CALCULATION_ONGOING: 'CALCULATION_ONGOING',
    CALCULATION_FAILED: 'CALCULATION_FAILED',
    GENERATED: 'GENERATED',
    PUBLISHED: 'PUBLISHED',
    UNPUBLISHED: 'UNPUBLISHED',
};
export const useResultStatus = ({ farmSeason, carbonModel, carbonProgrammeSlug }) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { canValidateResults } = usePermission();
    const canPublishOrUnpublish = canValidateResults;
    const disablePublishResultsButton = carbonProgrammeSlug === 'carbon-programme-5';
    const hasValidatedData = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.summary_validated_at);
    const hasResults = !!(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.has_results);
    const unpublishedStatus = t('farmers.results.unpublished_at', {
        date: formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_unpublished_at),
    });
    const publishedStatus = t('farmers.results.published_at', {
        date: formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_validated_by_agronomist_at),
    });
    const generatedStatus = t('farmers.results.generated_at', {
        date: formatDate(user === null || user === void 0 ? void 0 : user.language.iso_code, farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_generated_at),
    });
    const areResultsValidatedByAgronomist = !!((farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.has_results) && (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_validated_by_agronomist_at));
    const { data: publishedBy } = useGetUserNameByIdQuery(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_validated_by, {
        skip: !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_validated_by),
    });
    const { data: unpublishedBy } = useGetUserNameByIdQuery(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_unpublished_by, {
        skip: !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_unpublished_by),
    });
    const { data: generatedBy } = useGetUserNameByIdQuery(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_generated_by, {
        skip: !(farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_generated_by),
    });
    const getResultStatus = () => {
        if (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_unpublished_at)
            return RESULT_STATUSES.UNPUBLISHED;
        if (areResultsValidatedByAgronomist)
            return RESULT_STATUSES.PUBLISHED;
        if ((farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_calculation_status) === RESULTS_CALCULATION_STATUS.SUCCEEDED)
            return RESULT_STATUSES.GENERATED;
        if ((farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_calculation_status) === RESULTS_CALCULATION_STATUS.ONGOING)
            return RESULT_STATUSES.CALCULATION_ONGOING;
        if ((farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_calculation_status) === RESULTS_CALCULATION_STATUS.FAILED)
            return RESULT_STATUSES.CALCULATION_FAILED;
        // DNDC YoY currently don't handle results_calculation_status
        if (hasValidatedData &&
            farmSeason.baseline_farm_season_id !== null &&
            carbonModel === CARBON_RESULT_MODEL.DNDC &&
            (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_calculation_status) === RESULTS_CALCULATION_STATUS.UNPROCESSED)
            return RESULT_STATUSES.CALCULATION_ONGOING;
        return RESULT_STATUSES.NOT_STARTED;
    };
    const getIsActiveResultStatus = () => {
        if (carbonModel === CARBON_RESULT_MODEL.CFT) {
            return hasValidatedData || areResultsValidatedByAgronomist;
        }
        else if (carbonModel === CARBON_RESULT_MODEL.DNDC) {
            return hasResults;
        }
        return false;
    };
    const mapResultStatusToLabel = (status) => {
        switch (status) {
            case RESULT_STATUSES.NOT_STARTED:
                return t('labels.not-started', { context: 'male' });
            case RESULT_STATUSES.CALCULATION_ONGOING:
                return t('farmers.results.calculation');
            case RESULT_STATUSES.CALCULATION_FAILED:
                return t('farmers.results.calculation-failed');
            case RESULT_STATUSES.GENERATED:
                return generatedStatus;
            case RESULT_STATUSES.PUBLISHED:
                return publishedStatus;
            case RESULT_STATUSES.UNPUBLISHED:
                return unpublishedStatus;
        }
    };
    const getLabelInfo = () => {
        // we display an info because DNDC YoY results generation is currently "manual"
        if (hasValidatedData &&
            carbonModel === CARBON_RESULT_MODEL.DNDC &&
            (farmSeason === null || farmSeason === void 0 ? void 0 : farmSeason.results_calculation_status) === RESULTS_CALCULATION_STATUS.UNPROCESSED)
            return t('farmers.results.yoy-results-generation-label-description');
        return null;
    };
    const status = getResultStatus();
    const translatedStatus = mapResultStatusToLabel(status);
    const isActive = getIsActiveResultStatus();
    const isPublished = status === RESULT_STATUSES.PUBLISHED;
    const labelInfo = getLabelInfo();
    const resultsStatus = {
        label: t('labels.results'),
        isActive,
        status,
        value: translatedStatus,
        author: publishedBy || unpublishedBy || generatedBy,
        hasResults,
        isPublished,
        labelInfo,
    };
    return { canPublishOrUnpublish, resultsStatus, areResultsValidatedByAgronomist, disablePublishResultsButton };
};
