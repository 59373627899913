import { makeStyles } from 'tss-react/mui';
export const useNewSelectStyle = makeStyles()((theme) => ({
    checkboxOption: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    checkbox: {
        padding: 0,
    },
    valuesContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    optionAll: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '100%',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    menuPaper: {
        maxHeight: '320px !important',
    },
}));
