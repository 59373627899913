import { useGetFarmersListQuery } from '@services';
import { useAppSelector } from '@hooks';
import { selectFarmersListSeasonIds, selectFarmersListPage, selectFarmersListSearch, selectFarmersListCohortIds, selectFarmersListContactIds, selectFarmersListUserStatuses, } from '@features/farmer/listFarmers/newFarmersList.slice';
import { PAGE_SIZE } from './Table/useNewFarmersTableData';
import { skipToken } from '@reduxjs/toolkit/query';
export const useGetFarmersList = () => {
    const seasonIds = useAppSelector(selectFarmersListSeasonIds);
    const cohortIds = useAppSelector(selectFarmersListCohortIds);
    const contactIds = useAppSelector(selectFarmersListContactIds);
    const page = useAppSelector(selectFarmersListPage);
    const search = useAppSelector(selectFarmersListSearch);
    const userStatuses = useAppSelector(selectFarmersListUserStatuses);
    const queryParams = {
        seasonId: seasonIds,
        cohortId: cohortIds,
        contactId: contactIds,
        search: search,
        userStatus: userStatuses,
        page: page,
        pageSize: PAGE_SIZE,
    };
    // Serialize the object into a query string
    const computedQueryString = Object.entries(queryParams)
        .filter(([, values]) => (Array.isArray(values) ? (values === null || values === void 0 ? void 0 : values.length) > 0 : values !== null))
        .map(([key, values]) => Array.isArray(values)
        ? values.map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        : `${encodeURIComponent(key)}=${encodeURIComponent(values)}`)
        .flat()
        .join('&');
    const queryString = computedQueryString ? `?${computedQueryString}` : '';
    const { data, isLoading, isFetching, isError } = useGetFarmersListQuery(seasonIds.length === 0 ? skipToken : { queryString });
    return {
        data: (data === null || data === void 0 ? void 0 : data.farmers) || [],
        count: (data === null || data === void 0 ? void 0 : data.count) || 0,
        isLoading,
        isFetching,
        isError,
    };
};
