import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useGetCohorts } from '@hooks/useGetCohorts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewSelect } from '@components/NewSelect/NewSelect';
export const CohortFilter = ({ defaultValue, onChange }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : []);
    const { data: cohorts } = useGetCohorts();
    const handleChange = (values) => {
        setInputValue(values);
        onChange === null || onChange === void 0 ? void 0 : onChange(values);
    };
    const getOptionLabel = (cohort) => cohort.slug;
    const getOptionValue = (cohort) => cohort.id;
    if (!cohorts)
        return _jsx(_Fragment, {});
    return (_jsx(NewSelect, { values: inputValue, onChange: handleChange, options: cohorts, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (cohorts, allAreSelected) => (_jsx(NewSelect.LabelWithPill, { label: t('common.cohort'), selected: cohorts.length, areAllSelected: allAreSelected })), renderOption: (option, props) => (_jsx(NewSelect.CheckboxOption, Object.assign({}, props, { label: `${t(`farmers.cohort.${option.slug}`)} (${option.year})` }))), renderOptionAll: (props) => _jsx(NewSelect.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};
