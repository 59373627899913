import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FarmersTableHeader } from '@features/farmer/listFarmers/Header/FarmersTableHeader';
import { FarmersTable } from '@features/farmer/listFarmers/Table/FarmersTable';
import { NewFarmersTable } from '@features/farmer/listFarmers/Table/NewFarmersTable';
import { useFarmersPageStyle } from './useFarmersPage.style';
import { NewFarmersTableHeader } from '@features/farmer/listFarmers/Header/NewFarmersTableHeader';
// TODO to be removed
const FarmersPage = () => {
    const showNewPage = process.env.DISPLAY_NEW_FARMERS_LIST_PAGE === 'true';
    return showNewPage ? _jsx(NewFarmersPage, {}) : _jsx(OldFarmersPage, {});
};
// TODO to be renamed
const NewFarmersPage = () => {
    const { classes } = useFarmersPageStyle();
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(NewFarmersTableHeader, {}), _jsx(NewFarmersTable, {})] })));
};
// TODO to be removed
const OldFarmersPage = () => {
    const { classes } = useFarmersPageStyle();
    return (_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(FarmersTableHeader, {}), _jsx(FarmersTable, {})] })));
};
export default FarmersPage;
