const SEASON_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-season-filter';
const COHORT_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-cohort-filter';
const CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-contact-filter';
const USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY = 'farmers-list-user-status-filter';
const parseStorageObject = (key) => !!localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : undefined;
export const useFarmersListStorageFilters = () => {
    const storageSeasonFilter = parseStorageObject(SEASON_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageCohortFilter = parseStorageObject(COHORT_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageContactFilter = parseStorageObject(CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storageUserStatusFilter = parseStorageObject(USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY);
    const storeSeasonFilter = (season) => localStorage.setItem(SEASON_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(season));
    const storeCohortFilter = (cohorts) => localStorage.setItem(COHORT_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(cohorts));
    const storeContactFilter = (contacts) => localStorage.setItem(CONTACT_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(contacts));
    const storeUserStatusFilter = (userStatuses) => localStorage.setItem(USER_STATUS_FILTER_FARMERS_LIST_STORAGE_KEY, JSON.stringify(userStatuses));
    return {
        storageSeasonFilter,
        storeSeasonFilter,
        storageCohortFilter,
        storeCohortFilter,
        storageContactFilter,
        storeContactFilter,
        storageUserStatusFilter,
        storeUserStatusFilter,
    };
};
