import { useFarmersListStorageFilters } from '@features/farmer/listFarmers/useFarmersListStorageFilters';
import { useGetSeasons } from '@hooks/useGetSeasons';
import { useAppDispatch } from '@hooks';
import { changeCohorts, changeContacts, changePage, changeSearch, changeSeasons, changeUserStatuses, } from '@features/farmer/listFarmers/newFarmersList.slice';
import { debounce } from '@mui/material';
import { useEffect } from 'react';
import { USER_STATUS } from '@/types/user.types';
import { ROLE_GROUP, useUserRoleGroup } from '@hooks/useUserRoleGroup';
export const useFarmersListFilters = () => {
    const { storageSeasonFilter, storeSeasonFilter, storageCohortFilter, storeCohortFilter, storageContactFilter, storeContactFilter, storageUserStatusFilter, storeUserStatusFilter, } = useFarmersListStorageFilters();
    const { roleGroup } = useUserRoleGroup();
    const { seasonIdsGroupedByYear, isLoading: isLoadingSeasons } = useGetSeasons();
    const dispatch = useAppDispatch();
    // Initialize the default season as there should always be a season selected
    useEffect(() => {
        var _a;
        if (!isLoadingSeasons)
            dispatch(changeSeasons({ seasonIds: (_a = storageSeasonFilter === null || storageSeasonFilter === void 0 ? void 0 : storageSeasonFilter.ids) !== null && _a !== void 0 ? _a : seasonIdsGroupedByYear[0].ids }));
    }, [isLoadingSeasons]);
    // Initialize redux store with storage values
    useEffect(() => {
        if (storageCohortFilter)
            dispatch(changeCohorts({ cohortIds: storageCohortFilter.map((cohort) => cohort.id) }));
        if (storageContactFilter)
            dispatch(changeContacts({ contactIds: storageContactFilter.map((contact) => contact.id) }));
        if (storageUserStatusFilter)
            dispatch(changeUserStatuses({ userStatuses: storageUserStatusFilter }));
    }, []);
    const handleSeasonChange = (seasons) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeSeasons({ seasonIds: seasons.ids }));
        storeSeasonFilter(seasons);
    };
    const handleSearchChange = debounce((search) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeSearch({ search }));
    }, 500);
    const handleSearchReset = () => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeSearch({ search: '' }));
    };
    const handleCohortChange = (cohorts) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeCohorts({ cohortIds: cohorts.map((cohort) => cohort.id) }));
        storeCohortFilter(cohorts);
    };
    const handleContactsChange = (contacts) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeContacts({ contactIds: contacts.map((contact) => contact.id) }));
        storeContactFilter(contacts);
    };
    const handleUserStatusChange = (userStatuses) => {
        dispatch(changePage({ page: 0 }));
        dispatch(changeUserStatuses({ userStatuses }));
        storeUserStatusFilter(userStatuses);
    };
    return {
        defaultSeason: storageSeasonFilter !== null && storageSeasonFilter !== void 0 ? storageSeasonFilter : seasonIdsGroupedByYear === null || seasonIdsGroupedByYear === void 0 ? void 0 : seasonIdsGroupedByYear[0],
        defaultCohorts: storageCohortFilter,
        defaultContacts: storageContactFilter,
        defaultUserStatuses: storageUserStatusFilter !== null && storageUserStatusFilter !== void 0 ? storageUserStatusFilter : (roleGroup === ROLE_GROUP.PARTNER ? [USER_STATUS.SUBSCRIBED] : undefined),
        handleSeasonChange,
        handleSearchChange,
        handleSearchReset,
        handleCohortChange,
        handleContactsChange,
        handleUserStatusChange,
    };
};
