import { makeStyles } from 'tss-react/mui';
export const useFarmersTableHeaderStyles = makeStyles()((theme) => ({
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    filtersContainerSkeleton: {
        display: 'flex',
        alignItems: 'center',
    },
    filtersContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    },
    filter: {
        marginRight: theme.spacing(2),
    },
    searchContainer: {
        width: 380,
        marginTop: theme.spacing(7),
    },
}));
