import { jsx as _jsx } from "react/jsx-runtime";
import { Table } from '@components/Table/Table';
import { TableLoader } from '@components/Table/TableLoader';
import { useFarmersTableStyles } from '@features/farmer/listFarmers/Table/FarmersTable.style';
import { useAppDispatch, useAppSelector } from '@hooks';
import { usePermission } from '@hooks/usePermission';
import { Box } from '@mui/material';
import { ROUTES } from '@router/routes';
import { useNavigate } from 'react-router-dom';
import { PAGE_SIZE, useNewFarmersTableData } from './useNewFarmersTableData';
import { useTranslation } from 'react-i18next';
import { USER_STATUS } from '@/types/user.types';
import { changePage, selectFarmersListPage } from '@features/farmer/listFarmers/newFarmersList.slice';
export const NewFarmersTable = () => {
    const { classes } = useFarmersTableStyles();
    const { columns, rows, getRowId, isLoading, isFetching, count } = useNewFarmersTableData();
    const navigate = useNavigate();
    const { canSeeAssociateFarmers, canSeeAssociateAssignedFarmers } = usePermission();
    const dispatch = useAppDispatch();
    const page = useAppSelector(selectFarmersListPage);
    const { t } = useTranslation();
    const onPageChange = (page) => dispatch(changePage({ page }));
    const handleGoToProfile = ({ row }) => {
        if (row.user_status === USER_STATUS.INVITATION_REFUSED)
            return;
        const invitationId = row === null || row === void 0 ? void 0 : row.invitation_id;
        const userId = row === null || row === void 0 ? void 0 : row.user_id;
        const queryString = (canSeeAssociateFarmers || canSeeAssociateAssignedFarmers) && userId
            ? `?userId=${userId}`
            : invitationId
                ? `?invitationId=${invitationId}`
                : '';
        navigate(`${ROUTES.FARMER_PROFILE}${queryString}`);
    };
    return (_jsx(Box, Object.assign({ style: { height: '100%' } }, { children: _jsx(Table, { className: classes.farmersTable, page: page, rowsPerPageOptions: [PAGE_SIZE], pageSize: PAGE_SIZE, onRowClick: handleGoToProfile, rowCount: count, localeText: {
                MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) => count === 0 ? '' : `${from} - ${to} ${t('common.of')} ${count}`,
                },
            }, columns: columns, rows: isLoading || isFetching ? [] : rows, autoHeight: false, getRowId: getRowId, loading: isLoading || isFetching, pagination: true, paginationMode: "server", onPageChange: onPageChange, hideFooter: false, components: { LoadingOverlay: TableLoader }, getRowClassName: (params) => `row-${params.row.user_status}`, isRowSelectable: () => false, disableVirtualization: true }) })));
};
