import { alpha } from '@mui/material/styles';
export const palette = {
    common: { black: '#000', white: '#fff' },
    primary: {
        light: alpha('#426771', 0.75),
        main: '#426771',
        dark: '#2E484F',
        contrastText: '#fff',
    },
    secondary: {
        light: alpha('#B07D4A', 0.75),
        main: '#B07D4A',
        dark: '#7B5733',
        contrastText: '#fff',
    },
    error: {
        light: alpha('#F44336', 0.1),
        main: '#F44336',
        dark: '#F44336',
        contrastText: '#fff',
    },
    warning: {
        light: alpha('#EC9235', 0.1),
        main: '#EC9235',
        dark: '#EC9235',
        contrastText: '#fff',
    },
    info: {
        light: alpha('#E7EDF9', 0.75),
        main: '#E7EDF9',
        dark: '#BAC4DB',
        contrastText: '#426771',
    },
    success: {
        light: alpha('#57BA65', 0.1),
        main: '#57BA65',
        dark: '#3CAC74',
        contrastText: '#fff',
    },
    grey: {
        50: '#fafafa',
        100: '#D1D8DB',
        200: '#eeeeee',
        300: '#d2d2d2',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
    },
    layout: {
        main: '#f6f3E5',
    },
    content: {
        main: '#fff',
    },
};
