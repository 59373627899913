var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DashboardCarbonResults } from '@features/dashboard/DashboardContent/DashboardCarbonResults/DashboardCarbonResults';
import { DashboardProgramme } from '@features/dashboard/DashboardContent/DashboardProgramme/DashboardProgramme';
import { selectCohortFilter, selectHarvestFilter, selectRegionFilter, selectSeasonId, } from '@features/dashboard/dashboardSlice';
import { useDashboardData } from '@features/dashboard/useDashboardData';
import { useAppSelector } from '@hooks';
import { Box, Button, Typography } from '@mui/material';
import { useGetSeasonsQuery } from '@services/season.api';
import { useTrackExportToPdfMutation } from '@services/segment.api';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';
import useDashboardPdfStyle from './DashboardPdf.style';
export const createPdf = () => __awaiter(void 0, void 0, void 0, function* () {
    const pdf = new JsPDF('portrait', 'pt', 'a4');
    const pdfElement = document.querySelector('#pdf');
    if (pdfElement) {
        pdfElement.style.opacity = '1';
        pdfElement.style.height = 'auto';
        pdfElement.style.overflow = 'visible';
        const data = yield html2canvas(pdfElement);
        const img = data.toDataURL('image/png');
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('export.pdf');
        pdfElement.style.opacity = '0';
        pdfElement.style.height = '0';
        pdfElement.style.overflow = 'hidden';
    }
});
export const PDFExportButtonText = () => {
    const { classes } = useDashboardPdfStyle();
    const { t } = useTranslation();
    const harvestFilter = useAppSelector(selectHarvestFilter);
    const regionFilter = useAppSelector(selectRegionFilter);
    const cohortFilter = useAppSelector(selectCohortFilter);
    const [trackPdfExport] = useTrackExportToPdfMutation();
    const handleExport = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield createPdf();
            yield trackPdfExport({
                harvest_filter: harvestFilter,
                region_filter: regionFilter,
                cohort_filter: cohortFilter,
            });
        }
        catch (e) {
            console.error('Could not create pdf');
        }
    });
    return (_jsx(Button, Object.assign({ variant: "contained", color: "info", onClick: handleExport, className: classes.export }, { children: `${t('common.export')} PDF` })));
};
export const DashboardPdf = () => {
    const { classes } = useDashboardPdfStyle();
    const selectedSeasonId = useAppSelector(selectSeasonId);
    const { data: seasons } = useGetSeasonsQuery();
    const { data: dashboardData } = useDashboardData();
    const { t } = useTranslation();
    const selectedSeason = seasons === null || seasons === void 0 ? void 0 : seasons.find((season) => (season === null || season === void 0 ? void 0 : season.id) === selectedSeasonId);
    return (_jsxs("div", Object.assign({ id: "pdf", style: { opacity: '0', height: 0, overflow: 'hidden' }, className: classes.contentContainer }, { children: [_jsxs(Typography, Object.assign({ mb: 4, textTransform: "capitalize", variant: "h1" }, { children: [t('common.harvest'), " ", selectedSeason === null || selectedSeason === void 0 ? void 0 : selectedSeason.harvest_year] })), _jsx(DashboardProgramme, { data: dashboardData.programme }), _jsx(Box, Object.assign({ mt: 4 }, { children: _jsx(DashboardCarbonResults, { data: dashboardData.carbonResults }) }))] })));
};
