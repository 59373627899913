import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    seasonIds: [],
    cohortIds: [],
    contactIds: [],
    userStatuses: [],
    search: '',
    page: 0,
};
export const farmersListSlice = createSlice({
    name: 'newFarmersList',
    initialState,
    reducers: {
        changeSeasons: (state, action) => {
            var _a;
            state.seasonIds = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.seasonIds;
        },
        changeCohorts: (state, action) => {
            var _a;
            state.cohortIds = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.cohortIds;
        },
        changeContacts: (state, action) => {
            var _a;
            state.contactIds = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.contactIds;
        },
        changeUserStatuses: (state, action) => {
            var _a;
            state.userStatuses = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.userStatuses;
        },
        changeSearch: (state, action) => {
            var _a;
            state.search = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.search;
        },
        changePage: (state, action) => {
            var _a;
            state.page = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.page;
        },
    },
});
export const selectFarmersListSeasonIds = (state) => state.newFarmersList.seasonIds;
export const selectFarmersListCohortIds = (state) => state.newFarmersList.cohortIds;
export const selectFarmersListContactIds = (state) => state.newFarmersList.contactIds;
export const selectFarmersListUserStatuses = (state) => state.newFarmersList.userStatuses;
export const selectFarmersListSearch = (state) => state.newFarmersList.search;
export const selectFarmersListPage = (state) => state.newFarmersList.page;
export const { changeSeasons, changeCohorts, changeContacts, changeUserStatuses, changeSearch, changePage } = farmersListSlice.actions;
// TODO rename
export const newFarmersListReducer = farmersListSlice.reducer;
