import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { NewSelect } from '@components/NewSelect/NewSelect';
import { useSeasonFilterStyles } from '@features/farmer/listFarmers/Filters/SeasonFilter/SeasonFilter.style';
import { capitalize, Typography } from '@mui/material';
import { useGetSeasonsFilter, } from '@features/farmer/listFarmers/Filters/SeasonFilter/useGetSeasonsFilter';
import { useTranslation } from 'react-i18next';
export const SeasonFilter = ({ defaultValue, onChange }) => {
    const { classes } = useSeasonFilterStyles();
    const { selectedSeason, handleSeasonsChange, options } = useGetSeasonsFilter({ defaultValue, onChange });
    const { t } = useTranslation();
    if (!options || options.length === 0)
        return _jsx(_Fragment, {});
    const getOptionLabel = (option) => option.year;
    const getOptionValue = (option) => option.ids.join(',');
    return (_jsx(NewSelect, { value: selectedSeason, options: options, onChange: handleSeasonsChange, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (season) => `${capitalize(t('common.season'))} ${season.year}`, renderOption: (option, props) => (_jsx(NewSelect.Option, Object.assign({ className: classes.seasonBox }, props, { children: _jsx(Typography, Object.assign({ className: classes.season, variant: "body1" }, { children: option.year })) }))), MenuProps: {
            PaperProps: {
                className: classes.container,
            },
        } }));
};
