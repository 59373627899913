import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewSelect } from '@components/NewSelect/NewSelect';
import { useGetMembers } from '@hooks/useGetMembers';
export const ContactFilter = ({ defaultValue, onChange }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : []);
    const { data: members } = useGetMembers();
    const membersWithoutPending = members === null || members === void 0 ? void 0 : members.filter((member) => !!(member === null || member === void 0 ? void 0 : member.user_id));
    const handleChange = (values) => {
        setInputValue(values);
        onChange === null || onChange === void 0 ? void 0 : onChange(values);
    };
    const getOptionLabel = (member) => `${member === null || member === void 0 ? void 0 : member.first_name} ${member === null || member === void 0 ? void 0 : member.last_name}`;
    const getOptionValue = (member) => member.id;
    if (!membersWithoutPending)
        return _jsx(_Fragment, {});
    return (_jsx(NewSelect, { values: inputValue, onChange: handleChange, options: membersWithoutPending, getOptionLabel: getOptionLabel, getOptionValue: getOptionValue, renderValue: (members, allAreSelected) => (_jsx(NewSelect.LabelWithPill, { label: t('common.contact'), selected: members.length, areAllSelected: allAreSelected })), renderOption: (option, props) => (_jsx(NewSelect.CheckboxOption, Object.assign({}, props, { label: `${option.first_name} ${option.last_name}` }))), renderOptionAll: (props) => _jsx(NewSelect.OptionAll, Object.assign({ label: t('common.all') }, props)) }));
};
