import { useState } from 'react';
import { useGetSeasons } from '@hooks/useGetSeasons';
export const useGetSeasonsFilter = ({ defaultValue, onChange }) => {
    const [selectedSeason, setSelectedSeason] = useState(defaultValue);
    const { seasonIdsGroupedByYear: options, isLoading } = useGetSeasons();
    const handleSeasonsChange = (season) => {
        setSelectedSeason(season);
        onChange === null || onChange === void 0 ? void 0 : onChange(season);
    };
    return { selectedSeason, handleSeasonsChange, options, isLoading };
};
